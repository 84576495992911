import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import ProductViewModal from './ProductViewModal'

import Routes from '../routes/Routes'
import './Layout.css'
import katalkBtn from '../assets/images/katalk_button.png'
import surveyBtn from '../assets/images/naver-pay-5000.png'
import instagramBtn from '../assets/images/instagram.png'
import WriteRDS from './WriteRDS';
import EventTracker from './EventTracker';

const Layout = () => {
    const pageName="layout";
    const pageCode="p0010";
    const transactionNo="-";
    let eventName="";
    let eventCode="";
    const tableName = 'tb_event_info';
    let values=[];
    const currentURL = "https://www.data101.io";
    const soruceName = "ryq1Z";
    const surveyURL = `${currentURL}/betatest?soruce=${soruceName}`;

    const trackEvent = async () => {
        let eventParams = {
            pageName: pageName,
            pageCode: pageCode,
            eventName: eventName,
            eventCode: eventCode,
            transactionNo: transactionNo,
        };

        values  = EventTracker(eventParams);

        // WriteRDS 호출로 데이터베이스에 적재
        await WriteRDS(tableName, values);
    };

    const clickSurvey = () => {
        eventName="event_btn";
        eventCode="e0019";
        trackEvent();
    };

    const clickKatalk = () => {
        eventName="katalk_btn";
        eventCode="e0018";
        trackEvent();
    };

    return (
        <BrowserRouter>
            <Route render={props => (
                <div>
                    <Header {...props} />
                    <div className="container">
                        <div className="main">
                            <Routes />
                        </div>
                    </div>
                    <br />

                    {/* <div className="surveyBtn">
                        <a href={surveyURL}
                            target="_blank" rel="noopener noreferrer" onClick={clickSurvey}>
                        <img src={surveyBtn}
                            alt="SurveyLink"
                        />
                        <p>설문참여</p>
                        </a>
                    </div> */}

                    {/* <div className="instagramBtn">
                        <a href="https://www.instagram.com/data101.official/"
                            target="_blank" rel="noopener noreferrer" onClick={clickInsta}>
                            <img src={instagramBtn}
                                alt="InstaLink"
                            />
                            <p>인스타그램</p>
                        </a>
                    </div> */}

                    <div className="katalkCSbtn">
                        <a href="http://pf.kakao.com/_SxltHG/chat"
                            target="_blank" rel="noopener noreferrer" onClick={clickKatalk}>
                            <img src={katalkBtn}
                                alt="KatalkCSbtn"
                            />
                            <p>카톡문의</p>
                        </a>
                    </div>
                    <Footer />
                    <ProductViewModal />
                </div>
            )} />
        </BrowserRouter>
    )
}

export default Layout
