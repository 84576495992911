import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router';
import { useDispatch } from 'react-redux';

import { addItem } from '../redux/shopping-cart/cartItemsSlide';
import { remove } from '../redux/product-modal/productModalSlice';

import Button from './Button';
import numberWithCommas from '../utils/numberWithCommas';
import Swal from 'sweetalert2';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import axios from 'axios';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import './Layout.css'
import loadingDefault from '../assets/images/loading_default.gif';
import '../sass/FileUpload.css'
// import visImgSample from '../assets/images/vis_example_ai.png';
import ImgSentimentSample from '../assets/images/ai-sentiment-img-default.png'
import ImgIntentSample from '../assets/images/ai-intent-img-default.png'

import errorMsgImg from '../assets/images/error_msg_img_resize.png'
import FileSelectBtn from './FileUpload';
import { rdsread, API_S3_GETOBJ1 } from '../config';
import InfoTooltip from './InfoTooltip';

import { sentimentClassifierAPI, intentClassifierAPI, mainColor, UploadFileAPI, gptinsightAPI } from '../config';
import WriteRDS from './WriteRDS';
import EventTracker from './EventTracker';

const ProductViewAI = (props) => {
    const refColumnSelect = useRef(null);
    let pageName="ai-algorithm";
    let pageCode="p0007";
    let eventCode="";
    let eventName="";
    let values = [];
    const tableNameEventInfo = 'tb_event_info';

    // 거래번호 난수생성 함수
    const createTransactionNo = (transactionNo_obj, length) => {
        if (typeof transactionNo_obj !== 'object' || transactionNo_obj === null) {
            throw new Error('transactionNo_obj must be an object');
        }

        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        while (true) {
            let transactionNo_temp = '';
            for (let i = 0; i < length; i++) {
                const randomIndex = Math.floor(Math.random() * characters.length);
                transactionNo_temp += characters.charAt(randomIndex);
            }

            const transactionNos = Object.values(transactionNo_obj).map(item => item.transaction_no);
            const exists = transactionNos.some(transactionNo => transactionNo === transactionNo_temp);

            if (!exists) {
                console.log(`transactionNo_temp 유니크: ${transactionNo_temp}`);
                setTransactionNo(transactionNo_temp);
                return;
            } else {
                console.log(`동일 거래번호 존재: ${transactionNo_temp}`);
            }
        }
    };

    // 쿼리해서 해당 사용자만 가져오기.
    const loadPaymentDB = async (tableName) => {
        try {

            //RDS
            const response = await axios.post(rdsread, {
                table_name: tableName,
            });

            // 응답에서 데이터 추출
            const responseData = response.data.body;
            let data;
            if (typeof responseData === 'string') {
                data = JSON.parse(responseData);
            } else {
                data = responseData;
            }
            console.log(`data : ${data}`);
            console.log(`tableName : ${tableName}`);

        } catch (error) {
            console.log(`error: ${error}`);
        }
    };

    // 버튼을 클릭했을 때 실행될 함수
    const scrollToColumnSelect = () => {
        if (refColumnSelect.current) {
            refColumnSelect.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };


    const disabledBtnColor = 'grey'
    const dispatch = useDispatch();

    let product = props.product || {
        title: "",
        price: '',
        image01: null,
        image02: null,
        categorySlug: "",
        colors: [],
        slug: "",
        size: [],
        description: "",
        descriptionTag: "",
        dataCrawlingType: [],
        urlInputGuideText: "",
        urlInputGuideTextSub: "",
    };
    const [completePopUpFlag, setCompletePopUpFlag] = useState(false);
    const [previewImg, setPreviewImg] = useState(product.imageDetail);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUploadBtnDisabled, setFileUploadBtnDisabled] = useState(false);
    const [fileUploadedSuccessfully, setFileUploadedSuccessfully] = useState(false);
    const [data, setData] = useState([]);
    const [dataUploadFlag, setDataUploadFlag] = useState(false);

    const [uploadFileHeaders, setUploadFileHeaders] = useState([]);
    const [selectedColumn, setSelectedColumn] = useState('');
    const [selectedDateColumn, setSelectedDateColumn] = useState('');
    const [fileValidation, setFileValidation] = useState(false);
    const [errorFlag, setErrorFlag] = useState(false);
    const [completeSentiment, setCompleteSentiment] = useState(false);
    const [completeIntent, setCompleteIntent] = useState(false);
    //----------------------파일/시각화 버튼 컬러----------------------//
    const [fileUploadBtnColor, setFileUploadBtnColor] = useState(disabledBtnColor);
    //------------------------------------------------------------//

    //-------------------output img 할당변수-------------------//
    const [imgClassification, setImgClassification] = useState(false);
    const [imgClassificationIntent, setImgClassificationIntent] = useState(null);

    //-------------------------------------------------------//
    const [s3FileSentiment, setS3FileSentiment] = useState(null);
    const [s3FileIntent, setS3FileIntent] = useState(null);

    const [classificationBtnIsClicked, setClassificationBtnIsClicked] = useState(false);
    const [classificationIntentBtnIsClicked, setClassificationIntentBtnIsClicked] = useState(false);

    const [loadingClassification, setLoadingClassification] = useState(false);
    const [imgClassificationTrendPos, setImgClassificationTrendPos] = useState(false);
    const [imgClassificationTrendNeg, setImgClassificationTrendNeg] = useState(false);

    // label_list = ['기능', '버그 리포트', '가격 및 결제', '스팸', '기타', '지원 요청']
    const [imgClassificationIntentTrendFunction, setImgClassificationIntentTrendFunction] = useState(false);
    const [imgClassificationIntentTrendBugReport, setImgClassificationIntentTrendBugReport] = useState(false);
    const [imgClassificationIntentTrendPay, setImgClassificationIntentTrendPay] = useState(false);
    const [imgClassificationIntentTrendSpam, setImgClassificationIntentTrendSpam] = useState(false);
    const [imgClassificationIntentTrendETC, setImgClassificationIntentTrendETC] = useState(false);
    const [imgClassificationIntentTrendHelpRequest, setImgClassificationIntentTrendHelpRequest] = useState(false);






    const [loadingClassificationIntent, setLoadingClassificationIntent] = useState(false);
    const [loadingFileUpload, setLoadingFileUpload] = useState(false);

    const [loadingComplete, setLoadingComplete] = useState(false);
    const [loadingAPIcomplete, setLoadingAPIcomplete] = useState(false);
    const [profileID, setProfileID] = useState('');
    const history = useHistory();  // useHistory 훅 초기화

    const [transactionNo, setTransactionNo] = useState("-");
    const [productCode, setProductCode] = useState('-');
    const [serviceCode, setServiceCode] = useState('-');

    const [insight, setInsight] = useState('');
    const [loadinginsight, setLoadingInsight] = useState(true);

    const maxAttempts = 60; // 5분 동안 5초에 1번씩 체크 (60 * 5초 = 300초 = 5분)
    const checkInterval = 5000; // 5000ms = 5초
    const [unixTime, setUnixTime] = useState(Math.floor(Date.now() / 1000));


    useEffect(() => {
        setPreviewImg(product.imageDetail);
        const storedProfileID = localStorage.getItem('profileID');
        setProfileID(storedProfileID || '');


        if (loadingFileUpload) {
            const maxWaitTime = 30000; // 최대 대기 시간
            const minWaitTime = 5000;  // 최소 대기 시간
            const start = Date.now();

            const checkLoadingStatus = () => {
                const elapsed = Date.now() - start;

                if (elapsed >= maxWaitTime) {
                    setLoadingFileUpload(false);
                    setLoadingComplete(true);
                } else if (elapsed >= minWaitTime && !loadingComplete) {
                    // False로 변경되면 타이머 즉시 종료
                    setLoadingComplete(true);
                } else {
                    setTimeout(checkLoadingStatus, 100); // 0.1초마다 상태 확인
                }
            };

            checkLoadingStatus();

            return () => clearTimeout(checkLoadingStatus); // 컴포넌트 unmount 시 타이머 정리
        }


        // 최대 30초 대기하며 이미지 로드되면 즉시 로딩 애니메이션 해제 로직 | 2024-10-16
        // if (loadingClassification && loadingClassificationIntent) {
        //     const maxWaitTime = 300000;
        //     const start = Date.now();

        //     const checkLoadingStatus = () => {
        //         const elapsed = Date.now() - start;

        //         // 최대 시간 초과
        //         if (elapsed >= maxWaitTime) {
        //             setLoadingClassification(false);
        //             setLoadingClassificationIntent(false);
        //             setErrorFlag(true);
        //             setLoadingComplete(true);
        //             Swal.fire({
        //                 icon: 'warning',
        //                 title: '데이터 분석 중 문제가 생겼어요',
        //                 html: '분석할 컬럼이 올바르게 선택되었는지<br/>확인 후 분석을 다시 시작해 주세요.',
        //                 confirmButtonText: '확인',
        //             });
        //         } else if (!loadingClassification && !loadingClassificationIntent) {
        //             // False로 변경되면 타이머 즉시 종료
        //             setLoadingComplete(true);
        //         } else {
        //             setTimeout(checkLoadingStatus, 100); // 0.1초마다 상태 확인
        //         }
        //     };

        //     checkLoadingStatus();

        //     return () => clearTimeout(checkLoadingStatus); // 컴포넌트 unmount 시 타이머 정리
        // }
    }, [product, loadingClassification, loadingClassificationIntent, errorFlag, sentimentClassifierAPI, intentClassifierAPI]);
    console.log(`profileID: ${profileID}`);

    const [selectedCheckboxe, setSelectedCheckboxes] = useState([1, 2, 3, 4, 5]);

    const checkboxTextData = [
        { id: 1, text: '리뷰 내용' },
        { id: 2, text: '리뷰 평점' },
        { id: 3, text: '리뷰 등록일' },
        { id: 4, text: '작성자명' },
        { id: 5, text: '좋아요 개수' },
    ];

    const optionPrices = {
        option1: 10000,
        option2: 15000,
        option3: 20000,
        option4: 25000,
        option5: 30000,
    };

    const [selectedOption, setSelectedOption] = useState('option1');
    const [estimatedCost, setEstimatedCost] = useState(optionPrices.option1);

    const handleOptionChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedOption(selectedValue);
        if (selectedValue && optionPrices[selectedValue] !== undefined) {
            const cost = optionPrices[selectedValue];
            setEstimatedCost(cost);
        } else {
            setEstimatedCost(0);
        }
    };

    ///////////////// 불용어 등록 ///////////////////
    const [queue1, setQueue1] = useState([]);
    const [queue2, setQueue2] = useState([]);
    const [input1, setInput1] = useState('');
    const [input2, setInput2] = useState('');

    const handleChange1 = (e) => {
        setInput1(e.target.value);
    };
    const handleKeyPress1 = (e) => {
        if (e.key === 'Enter' && input1.trim() !== '') {
            setQueue1([input1, ...queue1]);
            setInput1('');
        }
    };
    const handleRemove1 = (index) => {
        setQueue1(queue1.filter((_, i) => i !== index));
    };
    const handleChange2 = (e) => {
        setInput2(e.target.value);
    };
    const handleKeyPress2 = (e) => {
        if (e.key === 'Enter' && input2.trim() !== '') {
            setQueue2([input2, ...queue2]);
            setInput2('');
        }
    };
    const handleRemove2 = (index) => {
        setQueue2(queue2.filter((_, i) => i !== index));
    };
    ////////////////////////////////////////////////////

    const showWaitPopup = () => {
        Swal.fire({
            icon: 'info',
            title: '🤖 AI 분류를 시작했어요',
            html: '최대 5분까지 소요돼요. 잠시만 기다려주세요.<br/>새로고침하시면 파일 업로드부터 다시 하셔야 돼요.',
            timer: 300000,
            allowOutsideClick: false, // 팝업 밖을 클릭해도 닫히지 않음
            allowEscapeKey: false,    // ESC 키로 닫을 수 없게 설정
            allowEnterKey: false,     // 엔터 키로 닫을 수 없게 설정
            timerProgressBar: true,   // 타이머 진행 상황 표시
            didOpen: () => {
                Swal.showLoading();     // 팝업에 로딩 애니메이션 표시
            },
        });

        if (imgClassification && imgClassificationIntent) {
            Swal.close();  // 조건이 만족되면 팝업 닫기
        }

    };

    const trackEvent = async () => {
        let eventParams = {
            pageName: pageName,
            pageCode: pageCode,
            eventName: eventName,
            eventCode: eventCode,
            transactionNo: transactionNo,
        };

        values  = EventTracker(eventParams);

        // WriteRDS 호출로 데이터베이스에 적재
        await WriteRDS(tableNameEventInfo, values);
    };

    const uploadFileS3 = async () => {

        try {
            setLoadingFileUpload(true);
            setImgClassification(null);
            setImgClassificationTrendPos(null);
            setImgClassificationTrendNeg(null);
            setImgClassificationIntent(null);
            setImgClassificationIntentTrendFunction(null);
            setImgClassificationIntentTrendBugReport(null);
            setImgClassificationIntentTrendPay(null);
            setImgClassificationIntentTrendSpam(null);
            setImgClassificationIntentTrendETC(null);
            setImgClassificationIntentTrendHelpRequest(null);
            setSelectedColumn('');
            setSelectedDateColumn('');
            setInsight('')

            Swal.fire({
                title: '💨파일 업로드 중',
                html: '최대 10초까지 소요되니 잠시만 기다려주세요.',
                icon: 'info',
                allowOutsideClick: false,
                timer: 10000,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading(); // 로딩 애니메이션 시작
                },
            });

            eventName = "upload_file";
            eventCode = "e0014";
            trackEvent();

            if (!profileID) {
                Swal.fire({
                    icon: 'error',
                    title: '🎈로그인 필요!',
                    html: "AI 텍스트 분류는 무료이나 로그인이 필요해요 :)",
                    confirmButtonText: '로그인하러 가기',
                    showLoaderOnConfirm: false,  // 로딩 애니메이션 표시하지 않음
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
                history.push('/login');
            }

            // 파일 미선택 시 경고 팝업
            if (!fileUploadedSuccessfully) {
                Swal.fire({
                    icon: 'warning',
                    title: 'csv 파일을 업로드해 주세요',
                    confirmButtonText: '확인',
                    showLoaderOnConfirm: false,  // 로딩 애니메이션 표시하지 않음
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
                setLoadingFileUpload(false);
                setSelectedFile(null);
                return;
            }
            // 파일 용량 제한 초과 시
            if (selectedFile.size > 2.5e+6) {
                console.log(`업로드 파일 업로드 용량 초과 : ${selectedFile.size}`);
                Swal.fire({
                    icon: 'error',
                    title: '최대 용량 초과',
                    html: '2.5MB 이하의 csv 파일만 업로드해 주세요.',
                    confirmButtonText: '확인',
                    showLoaderOnConfirm: false,  // 로딩 애니메이션 표시하지 않음
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
                setLoadingFileUpload(false);
                setSelectedFile(null);
                return;
            }

            if (selectedFile.size <= 2.5e+6) { }
            else {
                Swal.fire({
                    icon: 'error',
                    title: '파일 업로드 실패',
                    html: 'csv 파일만 업로드해 주세요.<br/>문제 해결이 안 되면 카톡플러스친구로 문의바랍니다.',
                    confirmButtonText: '확인',
                    showLoaderOnConfirm: false,  // 로딩 애니메이션 표시하지 않음
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
                setLoadingFileUpload(false);
                setSelectedFile(null);
                return;
            }
            const fileType = selectedFile.name.split('.').pop();
            // dataPreviewSetup(fileType);
            const reader = new FileReader();
            const fileExtension = selectedFile.name.split('.').pop();
            console.log(`fileExtension: ${fileExtension}`);
            if (fileExtension != 'csv' && fileExtension != 'xlsx' && fileExtension != 'xls') {
                console.log(`업로드 파일 형식 미지원`);
                setFileValidation(false);
                Swal.fire({
                    icon: 'error',
                    title: '파일 업로드 실패',
                    text: '현재 csv 파일만 업로드 가능합니다.',
                    confirmButtonText: '확인',
                });
                setLoadingFileUpload(false);
                setSelectedFile(null);
                return;
            } else {
                console.log('유효한 파일 형식');

                setFileValidation(true);
                setFileUploadedSuccessfully(true);
                setFileUploadBtnColor(disabledBtnColor);

                // 파일을 읽는 방식을 정의 241006
                if (fileExtension === 'csv') {
                    reader.readAsText(selectedFile, 'UTF-8');  // UTF-8 인코딩으로 읽기
                } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                    reader.readAsArrayBuffer(selectedFile);  // XLSX 파일은 ArrayBuffer로 읽음
                }

                reader.onload = (e) => {
                    const fileData = e.target.result;
                    const generateUniqueHeader = (headers) => {

                        console.log(`-----generateUniqueHeader 함수 실행-----`);
                        console.log(`headers.length : ${headers.length}`);
                        console.log(`headers[0] : ${headers[0]}`);
                        console.log(`type(headers[0]) : ${typeof (headers[0])}`);
                        console.log(`headers[1] : ${headers[1]}`);
                        console.log(`headers 자체 : ${headers}`);
                        // const newHeaders = headers.map(item => typeof(item) === undefined ? "undefinedColumn" : item);
                        const newHeaders = Array.from(headers, item => {
                            if (typeof item === 'undefined') {
                                return "undefinedColumn";
                            } else {
                                return item;
                            }
                        })

                        const uniqueHeaders = [];
                        const headerCount = {};
                        console.log(`newHeaders[0] : ${newHeaders[0]}`);
                        console.log(`type(newHeaders[0]) : ${typeof (newHeaders[0])}`);
                        console.log(`newHeaders 자체 : ${newHeaders}`);

                        newHeaders.forEach((header, index) => {
                            console.log(`현재 header : ${header}`);

                            if (header === "" || header === "undefined") {
                                console.log(`현재 header 비어있거나 undefinde : ${header}`);
                                header = "undefinedColumn";
                            }

                            if (uniqueHeaders.includes(header)) {
                                let count = headerCount[header] || 1;
                                let newHeader = `${header}${count}`;

                                while (uniqueHeaders.includes(newHeader)) {
                                    count += 1;
                                    newHeader = `${header}${count}`;
                                }

                                uniqueHeaders.push(newHeader);
                                headerCount[header] = count + 1;
                            } else {
                                uniqueHeaders.push(header);
                                headerCount[header] = 1;
                            }
                        });

                        return uniqueHeaders;
                    };

                    if (fileExtension === 'csv') {
                        Papa.parse(fileData, {
                            header: true,
                            complete: (results) => {
                                const csvData = results.data;
                                console.log(`csvData: ${csvData}`);
                                let headers = Object.keys(csvData[0]);
                                console.log(`csvData[1]: ${Object.keys(csvData[1])}`);
                                headers = generateUniqueHeader(headers);
                                console.log(`headers: ${headers}`);
                                console.log(`headers type: ${typeof (headers)}`);
                                // 처음 5개의 행만 선택
                                const rows = csvData.slice(0, 5).map(Object.values);
                                console.log(`rows: ${rows}`);
                                setData([headers, ...rows]);
                            },
                        });
                    }
                    else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                        const workbook = XLSX.read(fileData, { type: 'binary' });
                        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                        let headers = jsonData[0];
                        console.log(`jsonData[0]: ${jsonData[0]}`);

                        headers = generateUniqueHeader(headers);
                        jsonData[0] = headers;
                        // 헤더의 일부 셀이 비어 있거나 "undefined"면 unique header 생성
                        if (jsonData.length > 0) {

                        }
                        setData(jsonData.slice(0, 5));
                    }
                };

                const formData = new FormData();
                formData.append('file', selectedFile);
                formData.append('profileID', profileID);

                try {
                    const response = await axios.post(UploadFileAPI, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then((res) => {
                        console.log('File uploaded successfully:', res.data);
                        setFileUploadedSuccessfully(true);
                        Swal.fire({
                            icon: 'success',
                            title: '파일 업로드 성공',
                            text: `이제 분석할 컬럼을 선택해 주세요`,
                            confirmButtonText: '확인',
                            showLoaderOnConfirm: false,  // 로딩 애니메이션 표시하지 않음
                            didOpen: () => {
                                Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                            }
                        });
                        setDataUploadFlag(true);
                        setLoadingFileUpload(false);
                    });

                } catch (error) {
                    console.error('Error uploading file:', error);
                    Swal.fire({
                        icon: 'error',
                        title: '👀파일 상태를 확인해 주세요',
                        html: '파일에 빈 행이 있거나 컬럼명이 없지 않은지 확인해 주세요 :)<br/>계속 문제 해결이 안 되면 카톡문의 부탁드려요',
                        confirmButtonText: '확인',
                        showLoaderOnConfirm: false,  // 로딩 애니메이션 표시하지 않음
                        didOpen: () => {
                            Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                        }
                    });
                    setDataUploadFlag(false);
                    setLoadingFileUpload(false);
                    setData([]);
                    setSelectedFile("");
                    setFileValidation(false);
                    setFileUploadedSuccessfully(false);
                }

            }
        } catch (error) {
            console.error('Error uploading file:', error);
            Swal.fire({
                icon: 'error',
                title: '👀파일 상태를 확인해 주세요',
                html: '파일에 빈 행이 있거나 컬럼명이 없지 않은지 확인해 주세요 :)<br/>계속 문제 해결이 안 되면 카톡문의 부탁드려요',
                confirmButtonText: '확인',
                showLoaderOnConfirm: false,  // 로딩 애니메이션 표시하지 않음
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
            });
            setDataUploadFlag(false);
            setLoadingFileUpload(false);
            setData([]);
            setSelectedFile("");
            setFileValidation(false);
            setFileUploadedSuccessfully(false);
        }
    }


    const onFileChange = (event) => {
        try {
            console.log(`event.target.files[0]: ${event.target.files[0]}`);
            setSelectedFile(event.target.files[0]);
            const reader = new FileReader();
            const fileExtension = event.target.files[0].name.split('.').pop();
            console.log(`fileExtension: ${fileExtension}`);
            // if (fileExtension != 'csv' && fileExtension != 'xlsx' && fileExtension != 'xls') {
            if (fileExtension != 'csv') {
                console.log(`업로드 파일 형식 미지원`);
                setFileValidation(false);
                Swal.fire({
                    icon: 'error',
                    title: '파일 업로드 실패',
                    text: 'csv 파일만 업로드 가능합니다. 변환후 업로드 해 주세요.',
                    confirmButtonText: '확인',
                    showLoaderOnConfirm: false,  // 로딩 애니메이션 표시하지 않음
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
                setSelectedFile(null);
                return;
            } else {
                // 유효한 파일 확인 case
                setFileUploadBtnColor(mainColor);
                setFileUploadedSuccessfully(true);
                // dataPreviewSetup(fileExtension);
            };

        } catch (err) {
            console.log(`error 발생: ${err}`);
        }
    };

    const calculateColumnWidths = () => {
        if (data.length === 0) return [];
        const columnWidths = data[0].map((_, colIndex) => {
            const maxLength = Math.max(
                ...data.map((row) => (row[colIndex] ? row[colIndex].toString().length : 0))
            );
            return maxLength * 9; // Adjust the multiplier as needed
        });
        return columnWidths;
    };

    const dataPreviewSetup = (fileExtension) => {
        try {
            const reader = new FileReader();
            console.log(`fileExtension: ${fileExtension}`);
            if (fileExtension != 'csv') {
                console.log(`업로드 파일 형식 미지원`);
                setFileValidation(false);
                Swal.fire({
                    icon: 'error',
                    title: '파일 업로드 실패',
                    text: 'csv 파일만 업로드 가능합니다. 변환후 업로드 해 주세요.',
                    confirmButtonText: '확인',
                    showLoaderOnConfirm: false,  // 로딩 애니메이션 표시하지 않음
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
                setSelectedFile(null);

                return;
            } else {
                console.log('유효한 파일 형식');
                setFileValidation(true);
                setFileUploadedSuccessfully(true);
                setFileUploadBtnColor(disabledBtnColor);
                reader.onload = (e) => {
                    const fileData = e.target.result;
                    if (fileExtension === 'csv') {
                        Papa.parse(fileData, {
                            header: true,
                            complete: (results) => {
                                const csvData = results.data;
                                console.log(`csvData: ${csvData}`);
                                const headers = Object.keys(csvData[0]);
                                console.log(`headers: ${headers}`);
                                console.log(`headers type: ${typeof (headers)}`);
                                const rows = csvData.map(Object.values);
                                console.log(`rows: ${rows}`);
                                setData([headers, ...rows]);
                            },
                        });
                    } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                        const workbook = XLSX.read(fileData, { type: 'binary' });
                        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                            header: 1

                        });
                        setData(jsonData.slice(0, 10));

                    }
                };
                if (fileExtension === 'csv') {
                    console.log("fileExtension === 'csv'");
                    reader.readAsText(selectedFile);
                } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                    console.log("fileExtension === 'xlsx' || fileExtension === 'xls'");
                    reader.readAsBinaryString(selectedFile);
                }

            }

        } catch (error) {
            console.error('Error uploading file:', error);
            Swal.fire({
                icon: 'error',
                title: '👀파일 상태를 확인해 주세요',
                html: '파일에 빈 행이 있거나 컬럼명이 없지 않은지 확인해 주세요 :)<br/>계속 문제 해결이 안 되면 카톡문의 부탁드려요',
                confirmButtonText: '확인',
                showLoaderOnConfirm: false,  // 로딩 애니메이션 표시하지 않음
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
            });
            setDataUploadFlag(false);
            setLoadingFileUpload(false);
            setData([]);
            setSelectedFile("");
            setFileValidation(false);
            setFileUploadedSuccessfully(false);
        }


    }

    const dataPreviewShow = () => {
        const columnWidths = calculateColumnWidths();
        return dataUploadFlag ? (
            <div>
                <br />
                <table style={{ borderCollapse: 'collapse', border: '0.5px solid grey' }}>
                    <thead>
                        <tr>
                            {data[0] &&
                                data[0].map((header, index) => (
                                    <th key={index} style={{ width: columnWidths[index] + 20, border: '0.5px solid grey', padding: '8px' }}>
                                        {header}
                                    </th>
                                ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.slice(1).map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row.map((cell, cellIndex) => (
                                    <td key={cellIndex} style={{ width: columnWidths[cellIndex] + 20, border: '0.5px solid grey', padding: '8px' }}>
                                        {cell}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        ) : (
            <div
                style={{
                    width: '100%',
                    height: '200px',
                    border: '0.5px solid grey',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0 auto',
                    fontSize: '1.5em',
                    borderRadius: '10px', // 둥근 모서리 추가
                    padding: '3px', // 내부 여백 추가
                    color: 'grey',
                }}
            >
                파일 업로드 시 일부 Data가 미리 보여집니다.
            </div>
        );
    };


    const handleColumnChange = (event) => {
        setSelectedColumn(event.target.value);
    };

    // 시계열 데이터
    const handleDateColumnChange = (event) => {
        setSelectedDateColumn(event.target.value);
    };

    // 분석할 컬럼 선택 통합
    const selectColumnTotal = () => {
        return dataUploadFlag ? (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '7px', }}>
                    <select value={selectedColumn} onChange={handleColumnChange}
                        style={{
                            padding: '10px', fontSize: '14px', borderRadius: '12px',
                            color: mainColor, cursor: 'pointer', border: `0.5px solid grey`,
                            transition: 'background-color 0.3s ease, color 0.3s ease'
                        }}>
                        <option value="" disabled>❗️[필수] 텍스트 분석할 열 선택 </option>
                        {data[0].map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                    <InfoTooltip text="❗️[필수] 텍스트가 포함된 열을 선택해 주세요" />
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '7px', }}>
                    <select value={selectedDateColumn} onChange={handleDateColumnChange}
                        style={{
                            padding: '10px', fontSize: '14px', borderRadius: '12px',
                            cursor: 'pointer', border: `0.5px solid grey`,
                            transition: 'background-color 0.3s ease, color 0.3s ease'
                        }}>
                        <option value="">[선택] 날짜정보(datetime) 열 선택 </option>
                        {data[0].map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                    <InfoTooltip text="날짜 관련 열을 선택하시면 시계열 분석 차트를 제공해 드려요" />
                </div>
            </div>
        )

            :
            (
                null
            )
    }


    const selectDateColumn = () => {
        return dataUploadFlag ? (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', height: '30px' }}>
                <select value={selectedDateColumn} onChange={handleDateColumnChange}>
                    <option value="" disabled>[선택] 날짜정보(datetime) 열 선택 </option>
                    {data[0].map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
                <InfoTooltip text="[선택] 날짜정보(datetime) 포함된 열을 선택해 주세요" />
            </div>
        )

            :
            (
                null
            )
    }

    const saveResultImg = (aiType) => { // 감정분류:sn // 의도분류:it
        if (!selectedFile) {
            Swal.fire({
                icon: 'warning',
                title: 'csv 파일을 먼저 업로드해 주세요',
                html: '현재 2.5MB 이하의 파일만 분석할 수 있어요',
                confirmButtonText: '확인',
            });
            return;
        }

        else if (!classificationBtnIsClicked) {
            Swal.fire({
                icon: 'warning',
                title: 'AI 분류 시작 버튼을 먼저 눌러주세요',
                confirmButtonText: '확인',
            });
            return;
        }

        else if (!imgClassification || !imgClassificationIntent) {
            Swal.fire({
                icon: 'warning',
                title: 'AI 분류가 완료되지 않았어요',
                html: '작업 완료까지 잠시만 기다려주세요',
                confirmButtonText: '확인',
            });
            return;
        }

        try {


            let imgResList = [];
            let imgFileNameList = [];

            let csvRes = "";
            let csvFileName = "";

            if (aiType === 'sa') {
                imgResList = [imgClassification, imgClassificationTrendPos, imgClassificationTrendNeg]
                imgFileNameList = [`data101_sentiment_donut_${selectedFile.name.replace('.csv', '')}_${unixTime}.jpg`,
                `data101_sentiment_trend_pos_${selectedFile.name.replace('.csv', '')}_${unixTime}.jpg`,
                `data101_sentiment_trend_neg_${selectedFile.name.replace('.csv', '')}_${unixTime}.jpg`]

                csvRes = s3FileSentiment;
                csvFileName = `data101_sentiment_result_${selectedFile.name.replace('.csv', '')}_${unixTime}.csv`;
                eventName = "download_ai_sentiment";
                eventCode = "e0007";
                trackEvent();

            }
            else if (aiType === 'intent') {
                imgResList = [imgClassificationIntent, imgClassificationIntentTrendFunction, imgClassificationIntentTrendBugReport,
                    imgClassificationIntentTrendPay, imgClassificationIntentTrendSpam, imgClassificationIntentTrendETC,
                    imgClassificationIntentTrendHelpRequest
                ]
                imgFileNameList = [`data101_intent_donut_${selectedFile.name.replace('.csv', '')}_${unixTime}.jpg`,
                `data101_intent_trend_function_${selectedFile.name.replace('.csv', '')}_${unixTime}.jpg`,
                `data101_intent_trend_bug-report_${selectedFile.name.replace('.csv', '')}_${unixTime}.jpg`,
                `data101_intent_trend_pay_${selectedFile.name.replace('.csv', '')}_${unixTime}.jpg`,
                `data101_intent_trend_spam_${selectedFile.name.replace('.csv', '')}_${unixTime}.jpg`,
                `data101_intent_trend_etc_${selectedFile.name.replace('.csv', '')}_${unixTime}.jpg`,
                `data101_intent_trend_help-request_${selectedFile.name.replace('.csv', '')}_${unixTime}.jpg`]

                csvRes = s3FileIntent;
                csvFileName = `data101_intent_result_${selectedFile.name.replace('.csv', '')}_${unixTime}.csv`;
                eventName = "download_ai_intent";
                eventCode = "e0008";
                trackEvent();
            }

            for (let i = 0; i < imgResList.length; i++) {
                try {
                    const downloadFile = imgResList[i];
                    const downloadFileName = imgFileNameList[i];
                    console.log(`downloadFileName: ${downloadFileName}`);

                    if (downloadFile) {
                        // Base64 데이터 부분 추출
                        const base64Data = downloadFile.split(',')[1];
                        const mimeString = downloadFile.split(',')[0].split(':')[1].split(';')[0];

                        // Base64 데이터를 Blob으로 변환
                        const byteCharacters = atob(base64Data);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        const blob = new Blob([byteArray], { type: mimeString });

                        // Blob을 Object URL로 변환
                        const url = URL.createObjectURL(blob);

                        // 다운로드 링크 생성 및 클릭 이벤트 트리거
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = downloadFileName;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);

                        // Object URL 해제
                        URL.revokeObjectURL(url);
                    }
                } catch (error) {
                    console.log(`error: ${error}`);
                }


            }

            //========================CSV 다운로드========================//
            // 파일 콘텐츠로 Blob 생성 (UTF-8 with BOM)
            const decodedFileContent = csvRes;
            const blobCSV = new Blob([decodedFileContent], { type: 'text/csv;charset=utf-8;' });
            const linkCSV = document.createElement('a');
            const urlCSV = URL.createObjectURL(blobCSV);
            linkCSV.href = urlCSV;
            linkCSV.setAttribute('download', csvFileName);  // 다운로드할 파일명 설정
            document.body.appendChild(linkCSV);
            linkCSV.click();
            document.body.removeChild(linkCSV);
            //==========================================================//



        } catch (error) {
            console.error(`파일 다운로드 중 오류 발생: `, error);
        }
    };

    const showResultDownBtn = (aiType) => {
        return (
            <div style={{ paddingLeft: '15px', paddingRight: '10px' }}>
                <Button
                    size="sm"
                    backgroundColor={(!loadingComplete) ? 'grey' : mainColor}
                    onClick={() => saveResultImg(aiType)}
                >
                    분류결과 다운로드
                </Button>
            </div>
        )
    }

    const showClassification = () => {
        return (
            <div>
                <br />
                <br />
                <div style={{ display: 'flex', gap: '10px' }}>
                    {handleVisClassification()}
                    {showErrorImg()}
                </div>
            </div >

        )
    }

    // 통합 분류 버튼 추가 | 24-10-05
    const AITotal = () => {

        eventName = "ai_classify";
        eventCode = "e0016";
        trackEvent();

        if (!selectedFile) {
            Swal.fire({
                icon: 'error',
                title: 'csv 파일을 먼저 업로드해 주세요',
                html: '현재 2.5MB 이하의 파일만 분석할 수 있어요',
                confirmButtonText: '확인',
            });
            return;
        }

        if (!fileUploadedSuccessfully) {
            Swal.fire({
                icon: 'error',
                title: '파일을 업로드해 주세요',
                html: `👆위에 '업로드하기' 버튼을 눌러주세요`,
                confirmButtonText: '확인',
            });
            return;
        }


        const reader = new FileReader();
        const fileExtension = selectedFile.name.split('.').pop();

        if (fileExtension === 'csv') {
            reader.readAsText(selectedFile, 'UTF-8');  // UTF-8 인코딩으로 읽기
        } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
            reader.readAsArrayBuffer(selectedFile);  // XLSX 파일은 ArrayBuffer로 읽음
        }

        reader.onload = (e) => {
            const fileData = e.target.result;

            Papa.parse(fileData, {
                header: true,
                complete: (results) => {
                    const csvData = results.data; // CSV 파일의 데이터

                    // 첫 번째 행을 헤더로 사용한 후 두 번째 행 탐색
                    if (csvData.length > 1) {
                        const secondRow = csvData[0]; // 두 번째 행 (index 1)
                        let validDateFound = false;

                        // // 날짜 형식 정규식
                        // const datePattern1 = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD 형식
                        // const datePattern2 = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/; // YYYY-MM-DD HH:MM:SS+09:00 형식

                        // // 두 번째 행의 데이터를 20열까지 탐색
                        // const keys = Object.keys(secondRow); // 헤더 정보
                        // for (let i = 0; i < Math.min(20, keys.length); i++) {
                        //     const cellValue = secondRow[keys[i]]; // 각 셀 값

                        //     // 셀 값이 존재하는지 확인
                        //     if (cellValue) {
                        //         console.log(`셀 값: ${cellValue}`); // 디버깅용 로그

                        //         // 날짜 형식인지 확인
                        //         if (datePattern1.test(cellValue) || datePattern2.test(cellValue)) {
                        //             validDateFound = true;
                        //             console.log(`유효한 날짜 발견: ${cellValue}`); // 디버깅용 로그
                        //             break; // 유효한 날짜가 있으면 중단
                        //         } else {
                        //             console.log(`유효하지 않은 날짜 형식: ${cellValue}`); // 디버깅용 로그
                        //         }
                        //     } else {
                        //         console.log(`비어있는 셀: ${keys[i]}`); // 디버깅용 로그
                        //     }
                        // }

                        // // (테스트 위한 주석처리) 유효한 날짜가 없으면 경고창 표시
                        // if (!validDateFound) {
                        //     Swal.fire({
                        //         icon: 'warning',
                        //         title: '날짜 형식을 맞춰주세요',
                        //         html: 'YYYY-MM-DD 형식 또는<br/>YYYY-MM-DD HH:MM:SS+09:00 형식 (ISO 8601)',
                        //         confirmButtonText: '확인',
                        //     });
                        //     return;
                        // }

                        if (selectedColumn) {
                            Swal.fire({
                                icon: 'info',
                                title: 'AI 텍스트 분류 시작',
                                text: `최대 3분까지 걸릴 수 있어요`,
                                confirmButtonText: '확인',
                            });
                            setUnixTime(Math.floor(Date.now() / 1000));
                            onClassification();
                            onClassificationIntent();

                            getResult();
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: '❗️분석할 컬럼 선택 필요',
                                html: "파일 업로드와 분석하실 컬럼을 먼저 선택해 주세요",
                                confirmButtonText: '확인',
                            });
                            return
                        }

                    }
                }
            });
        };


    }

    // 감정분류
    const onClassification = async () => {
        setLoadingClassification(true);
        setClassificationBtnIsClicked(true);
        console.log(`분류 버튼 클릭!`);
        console.log(`"profileID" : ${profileID},
                    "filename" : ${selectedFile.name},
                    "selectedColumn" : ${selectedColumn}
                    "selectedDateColumn" : ${selectedDateColumn}`
        );

        const req = {
            "profileID": profileID,
            "date_column": "testDATE",  // 날짜 컬럼 확인 필요
            "filename": selectedFile.name,
            "selectedColumn": selectedColumn,
            "date_column": selectedDateColumn,
            "transactionNo": transactionNo,
            "service_code": 'ai_0001',
            "product_code": 'ai_0001',
            "stopword": queue1,
            "proper_noun": queue2,
            "unix_time": unixTime,
        };


        console.log('Request Data:', req);
        console.log(`sentimentClassifierAPI : ${sentimentClassifierAPI}`);
        try {
            const res = await axios.post(sentimentClassifierAPI, req, {
                headers: {
                    'Content-Type': 'application/json', // JSON 데이터 전송
                },
            });

            if (res.status === 200 && res.data) {  // 요청이 성공했을 때만 실행
                console.log(`res : ${res.data}`);

                // // S3에 저장된 csv 파일 URL을 받아서 저장
                // setS3FileSentiment(res.data.s3FileSentiment);

                // // 이미지 받기
                // const resImgClassification = res.data.resImgSentiAnalysis;
                // setImgClassification(`data:image/jpeg;base64,${resImgClassification}`);
                // setLoadingClassification(false);  // 로딩 상태 해제
                // setCompleteSentiment(true);
                return;
            }

        } catch (error) {
            console.error('Request failed:', error);
            // setCompleteSentiment(true);
            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
            }
        }

    }


    // 의도분류 버튼 클릭 시
    const onClassificationIntent = async () => {
        setLoadingClassificationIntent(true);
        setClassificationIntentBtnIsClicked(true);
        console.log(`분류 버튼 클릭!`);
        console.log(`"profileID" : ${profileID}, "filename" : ${selectedFile.name}, "selectedColumn" : ${selectedColumn}`);
        console.log(`intentClassifierAPI : ${intentClassifierAPI}`);

        const req = {
            "profileID": profileID,
            "date_column": "testDATE",
            "filename": selectedFile.name,
            "selectedColumn": selectedColumn,
            "date_column": selectedDateColumn,
            "transactionNo": transactionNo,
            "service_code": 'ai_0002',
            "product_code": 'ai_0002',
            "stopword": queue1,
            "proper_noun": queue2,
            "unix_time": unixTime,
        };

        try {
            const res = await axios.post(intentClassifierAPI, req, {
                headers: {
                    'Content-Type': 'application/json', // JSON 데이터 전송
                },
            });

            if (res.status === 200 && res.data) {  // 요청이 성공했을 때만 실행
                console.log(`res : ${res.data}`);
                // 이미지 받기
                // const resImgClassificationIntent = res.data.resImgIntent;
                // setImgClassificationIntent(`data:image/jpeg;base64,${resImgClassificationIntent}`);
                // setLoadingClassificationIntent(false);
                // setCompleteIntent(true);
                // setS3FileIntent(res.data.s3FileIntent);
                return;
            }

        } catch (error) {
            console.log('Request failed:', error);
            // setCompleteIntent(false);
            if (error.response) {
                console.log('Response data:', error.response.data);
                console.log('Response status:', error.response.status);
            }
        }
    };

    // unix_time 프론트에서 만들어서 백단으로 보내는 방법으로 바꿔야 함
    const getResult = async () => {

        // 감정분류 또는 의도분류 중 하나라도 결괏값 못 가져온 경우
        if ((!completeSentiment || !completeIntent)) {
            console.log('getResult!');
            let attempts = 0;
            let loadFileName = `${selectedFile.name.replace('.csv', '')}`;
            const req = {
                "profileid": profileID,
                "serviceCode": 'ai_0001',
                "productCode": 'ai_0001',
                "request_cnt": "1000",
                "fileName": loadFileName,
                "unix_time": unixTime,
            };
            // const checkInterval = 5000;  // 5초 간격
            // const maxAttempts = 60;  // 최대 시도 횟수 (5초 * 60 = 5분)

            const makeRequest = async () => {
                attempts++;
                console.log(`${attempts}번째 시도`);

                try {

                    const response = await axios.post(API_S3_GETOBJ1, req, {
                        responseType: 'json'  // 서버에서 JSON 응답을 받기
                    })
                    // .then((res) => {
                    //     console.log(`res : ${res}`);
                    //     console.log(`res.data : ${res.data}`);
                    //     console.log(`Object.keys(res.data) : ${Object.keys(res.data)}`);
                    //     // console.log(`res.data.body : ${res.data.body}`);
                    //     console.log(`res.data.sttusCode : ${res.data.statusCode}`);
                    //     fileData = JSON.parse(res.data.body);  // JSON 문자열을 객체로 변환
                    //     console.log('fileData 파싱 성공:', fileData);
                    //     });

                    // console.log(`response.status : ${response.status}`);
                    let fileData = JSON.parse(response.data.body); // JSON 문자열을 객체로 변환
                    try {
                        console.log(`response.data.statusCode: ${response.data.statusCode}`);
                        console.log('fileData 파싱 성공:', fileData);
                    } catch (error) {

                    }
                    if (response.data.statusCode === 200 && Array.isArray(fileData) && fileData.length > 8) {
                        console.log('fileData 배열 확인:', fileData);
                        // if (selectedDateColumn) {
                        //     console.log('날짜 데이터 선택 시');
                        for (let i = 0; i < 11; i++) {
                            if (fileData[i]) {  // 배열 요소가 존재하는지 확인
                                if (fileData[i].fileName.includes('sa_trend') && fileData[i].fileName.includes('_pos')) {
                                    setImgClassificationTrendPos(`data:image/jpeg;base64,${fileData[i].content}`);
                                }

                                else if (fileData[i].fileName.includes('sa_trend') && fileData[i].fileName.includes('_neg')) {
                                    setImgClassificationTrendNeg(`data:image/jpeg;base64,${fileData[i].content}`);
                                }

                                else if (fileData[i].fileName.includes('sa_donut')) {
                                    setImgClassification(`data:image/jpeg;base64,${fileData[i].content}`);
                                }

                                else if (fileData[i].fileName.includes('intent_donut')) {
                                    setImgClassificationIntent(`data:image/jpeg;base64,${fileData[i].content}`);
                                }

                                else if (fileData[i].fileName.includes('sa_result_')) {
                                    setS3FileSentiment(fileData[i].content);
                                }

                                else if (fileData[i].fileName.includes('intent_result_')) {
                                    setS3FileIntent(fileData[i].content);
                                }

                                else if (fileData[i].fileName.includes('intent_trend_') && fileData[i].fileName.includes('_classified_기능')) {
                                    setImgClassificationIntentTrendFunction(`data:image/jpeg;base64,${fileData[i].content}`);
                                }

                                else if (fileData[i].fileName.includes('intent_trend_') && fileData[i].fileName.includes('_classified_버그 리포트')) {
                                    setImgClassificationIntentTrendBugReport(`data:image/jpeg;base64,${fileData[i].content}`);
                                }

                                else if (fileData[i].fileName.includes('intent_trend_') && fileData[i].fileName.includes('_classified_스팸')) {
                                    setImgClassificationIntentTrendSpam(`data:image/jpeg;base64,${fileData[i].content}`);
                                }

                                else if (fileData[i].fileName.includes('intent_trend_') && fileData[i].fileName.includes('_classified_가격 및 결제')) {
                                    setImgClassificationIntentTrendPay(`data:image/jpeg;base64,${fileData[i].content}`);
                                }

                                else if (fileData[i].fileName.includes('intent_trend_') && fileData[i].fileName.includes('_classified_기타')) {
                                    setImgClassificationIntentTrendETC(`data:image/jpeg;base64,${fileData[i].content}`);
                                }

                                else if (fileData[i].fileName.includes('intent_trend_') && fileData[i].fileName.includes('_classified_지원 요청')) {
                                    setImgClassificationIntentTrendHelpRequest(`data:image/jpeg;base64,${fileData[i].content}`);
                                }
                            }
                        }

                        setLoadingClassification(false);
                        setLoadingClassificationIntent(false);
                        setCompleteSentiment(true);
                        setCompleteIntent(true);
                        return; // 최대 시도 횟수에 도달하면 재귀 호출을 멈춤

                    } else {
                        console.log('fileData는 배열이 아님:', fileData);
                    }

                    // 최대 시도 횟수 도달 시 처리
                    if (attempts >= maxAttempts) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: '5분 동안 파일을 찾을 수 없습니다!',
                        });
                        return; // 최대 시도 횟수에 도달하면 재귀 호출을 멈춤
                    }

                } catch (error) {
                    console.error('API 호출 중 오류 발생:', error);
                }

                // 요청 완료 후 5초 후에 다시 요청
                setTimeout(makeRequest, checkInterval);
            };

            // 첫 번째 요청 시작
            makeRequest();

        } else {
            console.log('getResult 미실행');
            console.log(`completeSentiment: ${completeSentiment}, completeIntent: ${completeIntent}, 
                    classificationBtnIsClicked: ${classificationBtnIsClicked}, 
                    classificationIntentBtnIsClicked: ${classificationIntentBtnIsClicked}`);
        }
    };



    const handleVisClassification = () => {
        if (!classificationBtnIsClicked) {
            return (
                <div>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <div
                            style={{
                                width: '100%',
                                height: '10px',
                                display: 'flex',
                            }}
                        >
                            <h2>🤖 AI 감정 분류 결과</h2>
                            {showResultDownBtn('sa')}
                            <InfoTooltip text="전체 기간 및 월별 긍/부정 데이터 Trend를 보여드려요" />

                        </div>
                    </div >
                    <br /><br />
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {/* 파이 차트: 1/3 너비 */}
                        <img
                            src={ImgSentimentSample ? ImgSentimentSample : undefined}
                            alt="감정분류Sample"
                            style={{
                                width: 'calc(100% - 10px)',
                            }}
                        />
                    </div>

                    <br /><br /><br />
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <div
                            style={{
                                width: '100%',
                                height: '10px',
                                display: 'flex',
                            }}
                        >
                            <h2>🤖 AI 의도 분류 결과</h2>
                            {showResultDownBtn('intent')}
                            <InfoTooltip text="현재 App 리뷰 데이터 기준으로 학습된 분류 결과를 제공해요" />

                        </div>
                    </div >
                    <br /><br />
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img
                            src={ImgIntentSample ? ImgIntentSample : undefined}
                            alt={"의도분류Sample"}
                            style={{
                                width: 'calc(100% - 10px)', // 가로 길이의 1/2 설정, 간격 고려하여 조정
                            }}
                        />
                    </div>
                </div>
            )
        }

        if (loadingClassification || loadingClassificationIntent) {
            showWaitPopup();
            return (
                <div
                    style={{
                        width: '100%',
                        height: '400px',
                        border: '1px solid black',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0 auto',
                        fontSize: '1.5em',
                        borderRadius: '10px', // 둥근 모서리 추가
                        padding: '3px' // 내부 여백 추가
                    }}
                >
                    <img style={{ height: '150px' }} src={loadingDefault} alt="로딩 중..." />
                    <p>데이터 분류를 시작했어요! 최대 5분까지 걸려요 :)</p>
                </div>
            )
        }

        if (completeIntent && completeSentiment) {
            if (!insight) {
                Swal.fire({
                    icon: 'success',
                    title: '🤖 AI 텍스트 분류 완료!',
                    html: "다운로드 버튼을 누르면 분류 결과를 받으실 수 있어요",
                    confirmButtonText: '확인',
                    showLoaderOnConfirm: false,  // 로딩 애니메이션 표시하지 않음
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
            }

            const images = [
                { src: imgClassificationIntentTrendFunction ? imgClassificationIntentTrendFunction : undefined, alt: "📊 기능 관련 Trend" },
                { src: imgClassificationIntentTrendBugReport ? imgClassificationIntentTrendBugReport : undefined, alt: "📊 버그 리포트 관련 Trend" },
                { src: imgClassificationIntentTrendPay ? imgClassificationIntentTrendPay : undefined, alt: "📊 비용/결제 관련 Trend" },
                { src: imgClassificationIntentTrendSpam ? imgClassificationIntentTrendSpam : undefined, alt: "📊 스팸성 데이터 관련 Trend" },
                { src: imgClassificationIntentTrendETC ? imgClassificationIntentTrendETC : undefined, alt: "📊 기타 Trend" },
                { src: imgClassificationIntentTrendHelpRequest ? imgClassificationIntentTrendHelpRequest : undefined, alt: "📊 지원요청 관련 Trend" }
            ];

            // undefined가 아닌 이미지만 필터링하고, 2개씩 묶기
            const validImages = images.filter(img => img.src !== undefined);
            const rows = [];
            for (let i = 0; i < validImages.length; i += 2) {
                rows.push(validImages.slice(i, i + 2));
            }

            return (
                <div>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <div
                            style={{
                                width: '100%',
                                height: '10px',
                                display: 'flex'
                            }}
                        >
                            <h2>🤖 AI 감정 분류 결과</h2>
                            {showResultDownBtn('sa')}
                            <InfoTooltip text="전체 기간 및 월별 긍/부정 데이터 Trend를 보여드려요" />

                        </div>
                    </div >
                    <br /><br />
                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                        <img
                            src={imgClassification || undefined}
                            alt="감정분류결과(Pie)"
                            style={{
                                width: 'calc(200% / 5 - 10px)', // 가로 길이의 1/3
                            }}
                        />

                        {/* 트렌드 차트: 2/3 너비 */}


                        <div style={{ width: 'calc(300% / 5)', display: 'flex', flexDirection: 'column', gap: '10px' }}>

                            <h3 style={{ margin: '0 0 5px 0' }}>📊 긍정 Trend</h3>
                            <img
                                src={imgClassificationTrendPos || undefined}
                                alt="감정분류결과(긍정Trend)"
                                style={{
                                    width: '100%', // 트렌드 차트는 2/3 안에서 전체 너비 차지
                                }}
                            />
                            <h3 style={{ margin: '0 0 5px 0' }}>📊 부정 Trend</h3>
                            <img
                                src={imgClassificationTrendNeg || undefined}
                                alt="감정분류결과(부정Trend)"
                                style={{
                                    width: '100%', // 트렌드 차트는 2/3 안에서 전체 너비 차지
                                }}
                            />
                        </div>
                    </div>

                    <br /><br /><br />
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <div
                            style={{
                                width: '100%',
                                height: '10px',
                                display: 'flex',
                            }}
                        >
                            <h2>🤖 AI 의도 분류 결과</h2>
                            {showResultDownBtn('intent')}
                            <InfoTooltip text="현재 App 리뷰 데이터 기준으로 학습된 분류 결과를 제공해요" />

                        </div>
                    </div >
                    <br /><br />
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img
                            src={imgClassificationIntent ? imgClassificationIntent : undefined}
                            alt={"의도분류결과(Pie)"}
                            style={{
                                width: 'calc(200% / 5 - 10px)', // 가로 길이의 1/2 설정, 간격 고려하여 조정
                            }}
                        />
                    </div>
                    <br /><br />
                    <div>
                        {rows.map((row, rowIndex) => (
                            <div key={rowIndex} style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                                {row.map((img, index) => (
                                    <div key={index} style={{ width: 'calc(100% / 2 - 10px)' }}>
                                        <h3 style={{ margin: '0 0 5px 0' }}>{img.alt}</h3>
                                        <img
                                            src={img.src}
                                            alt={img.alt}
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>

                </div>
            )
        };


    }

    const showErrorImg = () => {
        if (errorFlag && !loadingClassification && !loadingClassificationIntent) {
            return (
                <img src={errorMsgImg} style={{ height: '50%', border: '0.5px solid grey' }}>
                </img>
            )
        }
    }
    // ChatGPT 인사이트
    const gptinsight = async () => {
        console.log(`GPT 인사이트 클릭!`);

        // 파일 업로드 여부 확인
        if (!selectedFile) {
            Swal.fire({
                icon: 'warning',
                title: 'csv 파일을 업로드해 주세요',
                html: '현재 2.5MB 이하의 파일만 분석할 수 있어요',
                confirmButtonText: '확인',
            });
            setLoadingFileUpload(false);
            setSelectedFile(null);
            return;
        }

        else if (!fileUploadedSuccessfully) {
            Swal.fire({
                icon: 'warning',
                title: 'csv 파일을 업로드해 주세요',
                confirmButtonText: '확인',
            });
            setLoadingFileUpload(false);
            setSelectedFile(null);
            return;
        }

        else if (!selectedColumn) {
            Swal.fire({
                icon: 'warning',
                title: '분석할 컬럼을 먼저 선택해 주세요',
                confirmButtonText: '확인',
            });
            return;
        }

        // 파일 용량 제한 초과 시
        if (selectedFile.size > 2.5e+6) {
            console.log(`업로드 파일 업로드 용량 초과 : ${selectedFile.size}`);
            Swal.fire({
                icon: 'error',
                title: '최대 용량 초과',
                html: '2.5MB 이하의 csv 파일만 업로드해 주세요.',
                confirmButtonText: '확인',
            });
            setLoadingFileUpload(false);
            setSelectedFile(null);
            return;
        }

        if (selectedFile.size <= 2.5e+6) { }
        else {
            Swal.fire({
                icon: 'error',
                title: '파일 업로드 실패',
                html: 'csv 파일만 업로드해 주세요.<br/>문제 해결이 안 되면 카톡플러스친구로 문의바랍니다.',
                confirmButtonText: '확인',
            });
            setLoadingFileUpload(false);
            setSelectedFile(null);
            return;
        }

        console.log(`"profileID" : ${profileID},
                     "filename" : ${selectedFile.name},
                     "selectedColumn" : ${selectedColumn}
                     "selectedDateColumn" : ${selectedDateColumn}`
        );

        const req = {
            "profileID": profileID,
            "filename": selectedFile.name,
            "selectedColumn": selectedColumn
        };

        try {

            console.log('Request Data:', req);
            console.log(`gptinsightAPI : ${gptinsightAPI}`);

            Swal.fire({
                title: 'AI 인사이트 분석 시작',
                html: '최대 30초까지 소요돼요. 잠시만 기다려주세요.<br/>새로고침하시면 파일 업로드부터 다시 하셔야 돼요.',
                icon: 'info',
                allowOutsideClick: false,
                timer: 30000,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading(); // 로딩 애니메이션 시작
                },
            });

            // ChatGPT API에 요청 보내기
            const res = await axios.post(gptinsightAPI, req, {
                headers: {
                    'Content-Type': 'application/json', // JSON 데이터 전송
                },
            });
            console.log('res :', res);

            // 응답 데이터 확인 및 처리
            const body = JSON.parse(res.data.body);  // body를 JSON으로 파싱
            const insightData = body.insight;         // insight 데이터 추출
            console.log('Received Insight:', insightData);
            if (insightData) {
                Swal.fire({
                    icon: 'success',
                    title: '🤖 AI 인사이트 분석 완료!',
                    html: "한 줄 요약 인사이트를 확인해 보세요",
                    confirmButtonText: '확인',
                    showLoaderOnConfirm: false,  // 로딩 애니메이션 표시하지 않음
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
            }

            // 인사이트 도출 문자열을 어딘가에 저장
            setInsight(insightData);
            Swal.close(); // Swal 종료

        } catch (error) {
            console.error('Request failed:', error);
            Swal.fire({
                title: '오류 발생',
                text: '인사이트 도출 중 오류가 발생했습니다.',
                icon: 'error',
                confirmButtonText: '확인',
            });
            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
            }
        } finally {
            // 로딩 상태 해제 또는 완료 후 처리
            setLoadingInsight(false);  // 예: 로딩 상태를 false로 설정 (setLoading은 useState로 관리 가능)
        }
    };


    return (
        <div>
            <div>
                <img src={previewImg} alt="" style={{ width: '100%', height: 'auto' }} />
            </div>
            <br />
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '7px' }}>
                <h2>🗂️ 분석할 Data 업로드하기</h2>
                <InfoTooltip text="현재 2.5MB 이하의 CSV 파일만 분석 가능해요" />
            </div>
            <br />
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '3px' }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '3px' }}>
                    <div className='file-upload'>
                        <input type="file" id="ex_file" onChange={onFileChange}
                            style={{ display: 'none' }} /> {/* 기본 파일 업로드 input 숨김 */}
                        <div className="button-container" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            {selectedFile && (
                                <h3>
                                    <strong>📍 업로드된 파일 : </strong> {selectedFile.name} ({selectedFile.size} bytes)
                                </h3>
                            )}
                            <FileSelectBtn btnName={"파일 선택"}></FileSelectBtn>

                            <Button size="sm"
                                onClick={uploadFileS3}>업로드하기</Button>
                        </div>
                    </div>
                </div>

            </div>
            <br />
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                <h2>🔍 분석할 데이터 선택하기</h2>
                {selectColumnTotal()}
                <div>
                    <Button
                        size="sm"
                        // disabled={!selectedColumn}
                        backgroundColor={mainColor}
                        onClick={AITotal}
                    >
                        AI 분류 시작하기
                    </Button>
                </div>
            </div>
            <br />
            {dataPreviewShow()}
            <br />
            <br />
            <br />
            <div>{showClassification()}</div>
            <br />
            <br />
            <br />
            <div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <h2>😃 생성형 AI로 인사이트 발굴하기</h2>
                    <Button
                        size="sm"
                        backgroundColor={mainColor}
                        onClick={gptinsight}
                    >
                        {'인사이트 도출'}
                    </Button>
                    <InfoTooltip text="생성형 AI로 데이터의 핵심 인사이트를 한 줄로 요약해 드려요" />
                </div>
                <br />
                <p style={{ color: 'grey' }}>* 파일을 업로드 후 분석에 사용할 열을 먼저 선택해주세요</p><br />
                <p style={{ color: 'grey' }}>* 버튼 클릭후 최대 30초를 대기해주세요</p>

                {/* 결과 텍스트 출력 */}
                <div style={{ marginTop: '20px' }}>
                    {loadinginsight ? (
                        <p></p>
                    ) : (
                        <div
                            style={{
                                padding: '13px',
                                border: '1px solid #4d7faa',  // 외곽 테두리
                                borderRadius: '8px',          // 둥근 모서리
                                backgroundColor: '#f9f9f9',   // 배경색 추가
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // 그림자 효과
                                display: 'inline-block',      // 텍스트 크기에 맞게 크기 조정
                            }}
                        >
                            <p
                                style={{
                                    color: '#333',
                                    fontWeight: 'bold',       // 글자 굵게
                                    fontSize: '18px',         // 글자 크기
                                    margin: 0,                // 여백 제거
                                }}
                            >
                                {insight ? `${insight}` : '...'}
                            </p>
                        </div>
                    )}
                </div>
                <br />
            </div>
        </div>
    );
};

ProductViewAI.propTypes = {
    product: PropTypes.object,
};

export default withRouter(ProductViewAI);
