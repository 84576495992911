import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom'
import axios from 'axios';
import Button from '../components/Button'

import Papa from 'papaparse'; // 엑셀 파싱 모듈
import { saveAs } from 'file-saver'; // 파일 저장 모듈
import EventTracker from '../components/EventTracker';
import ProgressBarScrap from '../components/ProgressBarScrap'; // 크롤링 진척 확인용
import Swal from 'sweetalert2';

import { UploadFileAPI } from '../config';
import InfoTooltip from '../components/InfoTooltip'; // 데이터 다운로드 받을 수 있는 곳 안내용

import WriteRDS from '../components/WriteRDS';

import FileSelectBtn from '../components/FileUpload';
import loadingDefault from '../assets/images/loading_default.gif';

import * as XLSX from 'xlsx';

const mainColor = 'rgb(70, 100, 166)';
const Preprocess = () => {

    const disabledBtnColor = 'grey';
    const [profileID, setProfileID] = useState('');
    const [loadingComplete, setLoadingComplete] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [data, setData] = useState([]);
    const [dataUploadFlag, setDataUploadFlag] = useState(false);
    const [loadingFileUpload, setLoadingFileUpload] = useState(false);
    const [fileUploadBtnColor, setFileUploadBtnColor] = useState(disabledBtnColor);
    const [fileUploadedSuccessfully, setFileUploadedSuccessfully] = useState(false);
    const [fileValidation, setFileValidation] = useState(false);
    const [transactionNo, setTransactionNo] = useState("-");
    const [selectedColumn, setSelectedColumn] = useState('');

    const history = useHistory();  // useHistory 훅 초기화


    let pageName = "preprocess";
    let pageCode = "p0013";
    let eventCode = "";
    let eventName = "";
    let values = [];
    const tableNameEventInfo = 'tb_event_info';

    useEffect(() => {
        const storedProfileID = localStorage.getItem('profileID');
        setProfileID(storedProfileID || '');

        if (loadingFileUpload) {
            const maxWaitTime = 30000; // 최대 대기 시간
            const minWaitTime = 5000;  // 최소 대기 시간
            const start = Date.now();

            const checkLoadingStatus = () => {
                const elapsed = Date.now() - start;

                if (elapsed >= maxWaitTime) {
                    setLoadingFileUpload(false);
                    setLoadingComplete(true);
                } else if (elapsed >= minWaitTime && !loadingComplete) {
                    // False로 변경되면 타이머 즉시 종료
                    setLoadingComplete(true);
                } else {
                    setTimeout(checkLoadingStatus, 100); // 0.1초마다 상태 확인
                }
            };

            checkLoadingStatus();

            return () => clearTimeout(checkLoadingStatus); // 컴포넌트 unmount 시 타이머 정리
        }

        if (selectedFile) {
            uploadFileS3();
        }
    }, [profileID, selectedFile]);

    const handleColumnChange = (event) => {
        setSelectedColumn(event.target.value);
    };

    const trackEvent = async () => {
        let eventParams = {
            pageName: pageName,
            pageCode: pageCode,
            eventName: eventName,
            eventCode: eventCode,
            transactionNo: transactionNo,
        };

        values = EventTracker(eventParams);

        // WriteRDS 호출로 데이터베이스에 적재
        await WriteRDS(tableNameEventInfo, values);
    };

    const uploadFileS3 = async () => {

        try {
            setLoadingFileUpload(true);

            Swal.fire({
                title: '💨파일 업로드 중',
                html: '최대 10초까지 소요되니 잠시만 기다려주세요.',
                icon: 'info',
                allowOutsideClick: false,
                timer: 10000,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading(); // 로딩 애니메이션 시작
                },
            });

            eventName = "upload_file";
            eventCode = "e0014";
            trackEvent();

            if (!profileID) {
                Swal.fire({
                    icon: 'error',
                    title: '🎈로그인 필요!',
                    html: "데이터 시각화는 무료이나 로그인이 필요해요 :)",
                    confirmButtonText: '로그인하러 가기',
                });
                history.push('/login');
            }

            // 파일 미선택 시 경고 팝업
            // if (!fileUploadedSuccessfully) {
            //     Swal.fire({
            //         icon: 'warning',
            //         title: 'csv 파일을 업로드해 주세요',
            //         html: '현재 2.5MB 이하의 파일만 분석할 수 있어요',
            //         confirmButtonText: '확인',
            //         didOpen: () => {
            //             Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
            //         }
            //     });
            //     setLoadingFileUpload(false);
            //     setSelectedFile(null);
            //     return;
            // }

            // 파일 용량 제한 초과 시
            if (selectedFile.size > 2.5e+6) {
                console.log(`업로드 파일 업로드 용량 초과 : ${selectedFile.size}`);
                Swal.fire({
                    icon: 'error',
                    title: '최대 용량 초과',
                    html: '2.5MB 이하의 csv 파일만 업로드해 주세요.',
                    confirmButtonText: '확인',
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
                setLoadingFileUpload(false);
                setSelectedFile(null);
                return;

            }

            if (selectedFile.size <= 2.5e+6) { }
            else {
                Swal.fire({
                    icon: 'error',
                    title: '파일 업로드 실패',
                    html: 'csv 파일만 업로드해 주세요.<br/>문제 해결이 안 되면 카톡플러스친구로 문의바랍니다.',
                    confirmButtonText: '확인',
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
                setLoadingFileUpload(false);
                setSelectedFile(null);
                return;
            }
            const fileType = selectedFile.name.split('.').pop();
            // dataPreviewSetup(fileType);
            const reader = new FileReader();
            const fileExtension = selectedFile.name.split('.').pop();
            console.log(`fileExtension: ${fileExtension}`);
            if (fileExtension != 'csv' && fileExtension != 'xlsx' && fileExtension != 'xls') {
                console.log(`업로드 파일 형식 미지원`);
                setFileValidation(false);
                Swal.fire({
                    icon: 'error',
                    title: '파일 업로드 실패',
                    text: '현재 csv 파일만 업로드 가능합니다.',
                    confirmButtonText: '확인',
                });
                setLoadingFileUpload(false);
                setSelectedFile(null);
                return;
            } else {
                console.log('유효한 파일 형식');

                setFileValidation(true);
                setFileUploadedSuccessfully(true);
                setFileUploadBtnColor(disabledBtnColor);

                // 파일을 읽는 방식을 정의 241006
                if (fileExtension === 'csv') {
                    reader.readAsText(selectedFile, 'UTF-8');  // UTF-8 인코딩으로 읽기
                } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                    reader.readAsArrayBuffer(selectedFile);  // XLSX 파일은 ArrayBuffer로 읽음
                }

                reader.onload = (e) => {
                    const fileData = e.target.result;
                    const generateUniqueHeader = (headers) => {

                        console.log(`-----generateUniqueHeader 함수 실행-----`);
                        console.log(`headers.length : ${headers.length}`);
                        console.log(`headers[0] : ${headers[0]}`);
                        console.log(`type(headers[0]) : ${typeof (headers[0])}`);
                        console.log(`headers[1] : ${headers[1]}`);
                        console.log(`headers 자체 : ${headers}`);
                        // const newHeaders = headers.map(item => typeof(item) === undefined ? "undefinedColumn" : item);
                        const newHeaders = Array.from(headers, item => {
                            if (typeof item === 'undefined') {
                                return "undefinedColumn";
                            } else {
                                return item;
                            }
                        })

                        const uniqueHeaders = [];
                        const headerCount = {};
                        console.log(`newHeaders[0] : ${newHeaders[0]}`);
                        console.log(`type(newHeaders[0]) : ${typeof (newHeaders[0])}`);
                        console.log(`newHeaders 자체 : ${newHeaders}`);

                        newHeaders.forEach((header, index) => {
                            console.log(`현재 header : ${header}`);

                            if (header === "" || header === "undefined") {
                                console.log(`현재 header 비어있거나 undefinde : ${header}`);
                                header = "undefinedColumn";
                            }

                            if (uniqueHeaders.includes(header)) {
                                let count = headerCount[header] || 1;
                                let newHeader = `${header}${count}`;

                                while (uniqueHeaders.includes(newHeader)) {
                                    count += 1;
                                    newHeader = `${header}${count}`;
                                }

                                uniqueHeaders.push(newHeader);
                                headerCount[header] = count + 1;
                            } else {
                                uniqueHeaders.push(header);
                                headerCount[header] = 1;
                            }
                        });

                        return uniqueHeaders;
                    };

                    if (fileExtension === 'csv') {
                        Papa.parse(fileData, {
                            header: true,
                            complete: (results) => {
                                const csvData = results.data;
                                console.log(`csvData: ${csvData}`);
                                let headers = Object.keys(csvData[0]);
                                console.log(`csvData[1]: ${Object.keys(csvData[1])}`);
                                headers = generateUniqueHeader(headers);
                                console.log(`headers: ${headers}`);
                                console.log(`headers type: ${typeof (headers)}`);
                                // 처음 5개의 행만 선택
                                const rows = csvData.slice(0, 5).map(Object.values);
                                console.log(`rows: ${rows}`);
                                setData([headers, ...rows]);
                            },
                        });
                    }
                    else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                        const workbook = XLSX.read(fileData, { type: 'binary' });
                        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                        let headers = jsonData[0];
                        console.log(`jsonData[0]: ${jsonData[0]}`);

                        headers = generateUniqueHeader(headers);
                        jsonData[0] = headers;
                        // 헤더의 일부 셀이 비어 있거나 "undefined"면 unique header 생성
                        if (jsonData.length > 0) {

                        }
                        setData(jsonData.slice(0, 5));
                    }
                };

                const formData = new FormData();
                formData.append('file', selectedFile);
                formData.append('profileID', profileID);

                try {
                    const response = await axios.post(UploadFileAPI, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then((res) => {
                        console.log('File uploaded successfully:', res.data);
                        setFileUploadedSuccessfully(true);
                        Swal.fire({
                            icon: 'success',
                            title: '파일 업로드 성공',
                            text: `이제 분석할 컬럼을 선택해 주세요`,
                            confirmButtonText: '확인',
                            didOpen: () => {
                                Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                            }
                        });
                        setDataUploadFlag(true);
                        setLoadingFileUpload(false);
                    });

                } catch (error) {
                    console.error('Error uploading file:', error);
                    Swal.fire({
                        icon: 'error',
                        title: '👀파일 상태를 확인해 주세요',
                        html: '파일에 빈 행이 있거나 컬럼명이 없지 않은지 확인해 주세요 :)<br/>계속 문제 해결이 안 되면 카톡문의 부탁드려요',
                        confirmButtonText: '확인',
                        didOpen: () => {
                            Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                        }
                    });
                    setDataUploadFlag(false);
                    setLoadingFileUpload(false);
                    setData([]);
                    setSelectedFile("");
                    setFileValidation(false);
                    setFileUploadedSuccessfully(false);
                }

            }
        } catch (error) {
            console.log(`파일 업로드 중 에러 발생 : ${error}`);
            Swal.fire({
                icon: 'error',
                title: '👀파일 상태를 확인해 주세요',
                html: '파일에 빈 행이 있거나 컬럼명이 없지 않은지 확인해 주세요 :)<br/>계속 문제 해결이 안 되면 카톡문의 부탁드려요',
                confirmButtonText: '확인',
                didOpen: () => {
                    Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                }
            });
            setDataUploadFlag(false)
            setLoadingFileUpload(false);
            setFileValidation(false);
            setFileUploadedSuccessfully(false);
            setData([]);
            setSelectedFile("");
        }
    }


    const onFileChange = (event) => {
        try {
            console.log(`event.target.files[0]: ${event.target.files[0]}`);
            setSelectedFile(event.target.files[0]);
            const reader = new FileReader();
            const fileExtension = event.target.files[0].name.split('.').pop();
            console.log(`fileExtension: ${fileExtension}`);
            // if (fileExtension != 'csv' && fileExtension != 'xlsx' && fileExtension != 'xls') {
            if (fileExtension != 'csv') {
                console.log(`업로드 파일 형식 미지원`);
                setFileValidation(false);
                Swal.fire({
                    icon: 'error',
                    title: '파일 업로드 실패',
                    text: 'csv 파일만 업로드 가능합니다. 변환후 업로드 해 주세요.',
                    confirmButtonText: '확인',
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
                setSelectedFile(null);
                return;
            } else {
                // 유효한 파일 확인 case
                setFileUploadBtnColor(mainColor);
                setFileUploadedSuccessfully(true);
                // dataPreviewSetup(fileExtension);
            };

        } catch (err) {
            console.log(`error 발생: ${err}`);
        }
    };

    const calculateColumnWidths = () => {
        if (data.length === 0) return [];
        const columnWidths = data[0].map((_, colIndex) => {
            const maxLength = Math.max(
                ...data.map((row) => (row[colIndex] ? row[colIndex].toString().length : 0))
            );
            return maxLength * 9; // Adjust the multiplier as needed
        });
        return columnWidths;
    };

    const dataPreviewSetup = (fileExtension) => {
        try {
            const reader = new FileReader();
            console.log(`fileExtension: ${fileExtension}`);
            if (fileExtension != 'csv') {
                console.log(`업로드 파일 형식 미지원`);
                setFileValidation(false);
                Swal.fire({
                    icon: 'error',
                    title: '파일 업로드 실패',
                    text: 'csv 파일만 업로드 가능합니다. 변환후 업로드 해 주세요.',
                    confirmButtonText: '확인',
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
                setSelectedFile(null);
                return;
            } else {
                console.log('유효한 파일 형식');
                setFileValidation(true);
                setFileUploadedSuccessfully(true);
                setFileUploadBtnColor(disabledBtnColor);

                // 파일을 읽는 방식을 정의 위치 수정 241006
                if (fileExtension === 'csv') {
                    reader.readAsText(selectedFile, 'UTF-8');  // UTF-8 인코딩으로 읽기
                } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                    reader.readAsArrayBuffer(selectedFile);  // XLSX 파일은 ArrayBuffer로 읽음
                }

                reader.onload = (e) => {
                    const fileData = e.target.result;
                    if (fileExtension === 'csv') {
                        Papa.parse(fileData, {
                            header: true,
                            complete: (results) => {
                                const csvData = results.data;
                                console.log(`csvData: ${csvData}`);
                                const headers = Object.keys(csvData[0]);
                                console.log(`headers: ${headers}`);
                                console.log(`headers type: ${typeof (headers)}`);
                                const rows = csvData.map(Object.values);
                                console.log(`rows: ${rows}`);
                                setData([headers, ...rows]);
                            },
                        });
                    } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                        const workbook = XLSX.read(fileData, { type: 'binary' });
                        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                            header: 1

                        });
                        setData(jsonData.slice(0, 10));

                    }
                };
                if (fileExtension === 'csv') {
                    console.log("fileExtension === 'csv'");
                    reader.readAsText(selectedFile);
                } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                    console.log("fileExtension === 'xlsx' || fileExtension === 'xls'");
                    reader.readAsBinaryString(selectedFile);
                }

            }
        }
        catch (error) {
            setDataUploadFlag(false);
            setLoadingFileUpload(false);
            setData([]);
            setSelectedFile("");
            setFileValidation(false);
            setFileUploadedSuccessfully(false);
            Swal.fire({
                icon: 'error',
                title: '👀파일 상태를 확인해 주세요',
                html: '파일에 빈 행이 있거나 컬럼명이 없지 않은지 확인해 주세요 :)<br/>계속 문제 해결이 안 되면 카톡문의 부탁드려요',
                confirmButtonText: '확인',
                didOpen: () => {
                    Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                }
            });

        }

    }

    const dataPreviewShow = () => {
        const columnWidths = calculateColumnWidths();
        if (loadingFileUpload & !dataUploadFlag) {
            Swal.fire({
                title: '엑셀 업로드 중',
                html: "새로고침하지 마시고 잠시만 기다려주세요.<br/>최대 10초까지 소요될 수 있어요 :)",
                icon: 'info',
                timer: 5000,
                allowOutsideClick: false, // 팝업 밖을 클릭해도 닫히지 않음
                allowEscapeKey: false,    // ESC 키로 닫을 수 없게 설정
                allowEnterKey: false,     // 엔터 키로 닫을 수 없게 설정
                timerProgressBar: true,   // 타이머 진행 상황 표시
                didOpen: () => {
                    Swal.showLoading();     // 팝업에 로딩 애니메이션 표시
                },
                willClose: () => {
                    Swal.fire({
                        title: `엑셀 업로드 완료!`,
                        icon: 'success',
                        confirmButtonText: '확인',
                        confirmButtonColor: mainColor,
                    })
                }
            })
        }

        else if (!loadingFileUpload && dataUploadFlag) {
            return (
                <div>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '20px',
                        justifyContent: 'center',
                        padding: '10px'
                    }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '7px', }}>
                            <select value={selectedColumn} onChange={handleColumnChange}
                                style={{
                                    padding: '10px', fontSize: '14px', borderRadius: '12px',
                                    color: mainColor, cursor: 'pointer', border: `0.5px solid grey`,
                                    transition: 'background-color 0.3s ease, color 0.3s ease'
                                }}>
                                <option value="" disabled>❗️[필수] 텍스트 분석할 열 선택 </option>
                                {data[0].map((option, index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                            <Button
                                size="sm"
                                // disabled={!selectedColumn}
                                backgroundColor={mainColor}
                                onClick={requestPreprocess}
                            >
                                분석 시작하기
                            </Button>
                            {/* <InfoTooltip text="❗️[필수] 텍스트가 포함된 열을 선택해 주세요" /> */}
                        </div>
                    </div>
                    <br />
                    <table style={{ borderCollapse: 'collapse', border: '1px solid black' }}>
                        <thead>
                            <tr>
                                {data[0] &&
                                    data[0].map((header, index) => (
                                        <th key={index} style={{ width: columnWidths[index] + 20, border: '1px solid black', padding: '8px' }}>
                                            {header}
                                        </th>
                                    ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.slice(1).map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex} style={{ width: columnWidths[cellIndex] + 20, border: '1px solid black', padding: '8px' }}>
                                            {cell}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )
        }

        else if (!loadingFileUpload) {
            return (

                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '3px' }}>
                    <div className='file-upload'>
                        <input type="file" id="ex_file" onChange={onFileChange}
                            style={{ display: 'none' }} /> {/* 기본 파일 업로드 input 숨김 */}
                        <div className="button-container" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <FileSelectBtn btnName={"엑셀 업로드하기(.csv)"}></FileSelectBtn>
                        </div>
                    </div>
                </div>
            )
        }

    };

    const requestPreprocess = () => {
        Swal.fire({
            title: '👀인사이트풀한 데이터 찾는 중',
            html: '최대 10초까지 소요되니 잠시만 기다려주세요.',
            icon: 'info',
            allowOutsideClick: false,
            timer: 10000,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading(); // 로딩 애니메이션 시작
            },
            willClose: () => {
                Swal.fire({
                    title: `👻유의미한 데이터를 찾아냈어요!`,
                    icon: 'success',
                    confirmButtonText: '확인',
                    confirmButtonColor: mainColor,
                })
            }
        });
    }


    return (
        <div>
            <div style={{
                padding: '30px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start', // 위에서 시작하도록 설정
                textAlign: 'center',
                height: '100vh', // 화면 전체 높이
                // paddingTop: '30vh', // 위에서 30% 높이에 배치
                margin: 0, // 여백 제거
            }}>
                <h1>
                    Text 데이터 분석 시 꼭 읽어봐야 할
                    <br />
                    데이터와 문장들만 쏙쏙 찾아드려요!
                </h1>
                <br />
                <h3 style={{ color: 'grey' }}>더이상 모든 원문을 일일이 읽어보며 소중한 시간을 낭비하지 마세요🙏</h3>
                <br />
                {dataPreviewShow()}
            </div>
        </div>

    );
};

export default Preprocess;